import React, {useCallback, useMemo} from 'react';
import {
  InputBase,
  MenuItem,
  Select as SelectMUI,
  SelectProps as SelectMUIProps,
} from '@mui/material';
import {IconSVG} from '../../atoms/IconSVG';
import {Text} from '../../atoms/Text';
import {FormField} from '../FormField';
import {SelectProps} from './interfaces';
import {useStyles} from './styles';

const IconComponent: SelectMUIProps['IconComponent'] = props => (
  <IconSVG {...props} icon="arrow_down" size={24} />
);

const menuProps: SelectMUIProps['MenuProps'] = {
  sx: {
    '& .Mui-selected': {
      backgroundColor: 'unset',
      '&:hover': {
        backgroundColor: theme => theme.palette.gray1,
      },
      fontWeight: theme => theme.typography.fontWeightBold,
    },
    '& .Mui-focusVisible': {
      backgroundColor: theme => theme.palette.gray1,
    },
  },
};

const Select = ({
  value = '',
  onChange,
  onBlur,
  placeholder,
  options = [],
  label,
  disabled,
  error,
  helperText,
  warning,
  required,
}: SelectProps) => {
  const styles = useStyles();

  const handleChange = useCallback<
    Required<SelectMUIProps<string>>['onChange']
  >(e => onChange(e.target.value), [onChange]);

  const renderValue = useMemo<SelectMUIProps<string>['renderValue']>(() => {
    if (value === '') {
      return () => <Text disabled>{placeholder}</Text>;
    }
  }, [placeholder, value]);

  return (
    <FormField {...{label, helperText, disabled, error, required, warning}}>
      <SelectMUI
        value={value}
        disabled={disabled}
        onBlur={onBlur}
        onChange={handleChange}
        input={<InputBase />}
        sx={styles.select}
        IconComponent={IconComponent}
        displayEmpty
        renderValue={renderValue}
        MenuProps={menuProps}>
        {options.map(({value, label, disabled}) => (
          <MenuItem key={value} value={value} disabled={disabled}>
            {label}
          </MenuItem>
        ))}
      </SelectMUI>
    </FormField>
  );
};

export default React.memo(Select);
