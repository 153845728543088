export enum AnonymousRoutes {
  LOGIN = '/login',
  VERSACE_AUTH = '/auth/callback',
}

export enum AuthRoutes {
  MAIN = '/',
  CAMPAIGN_MANAGEMENT = '/campaign-management',
  USER_MANAGEMENT = '/user-management',
  CAMPAIGN_LIST = '/campaign-list',
  NO_PERMISSIONS = '/no-permissions',
  NO_ROLE = '/no-role',
  CALENDAR_DETAIL = '/campaign-management/:campaignId/calendar',
  APPOINTMENTS_MANAGEMENT = '/campaign-management/:campaignId/calendar/appointments',
  SEND_CAMPAIGN_INVITATION = '/campaign-management/:campaignId/calendar/send-invitation',
  CAMPAIGN_HISTORY = '/campaign-management/:campaignId/calendar/history',
  APPOINTMENTS_RESCHEDULING = '/campaign-management/:campaignId/calendar/appointments/:appointmentId/rescheduling',
  APPOINTMENT_REQUEST = '/appointment-request',
  APPOINTMENT_REQUEST_SUCCESS = '/appointment-request/success',
  APPOINTMENT_RESCHEDULE = '/appointment-reschedule',
  APPOINTMENT_RESCHEDULE_SUCCESS = '/appointment-reschedule/success',
  PRIVACY_POLICY = '/privacy',
  COOKIE_POLICY = '/cookies',
}
