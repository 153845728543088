import {useMemo} from 'react';
import {SxProps, Theme} from '@mui/material';

export const useStyles = () => {
  const menuItem = useMemo<SxProps<Theme>>(
    () => ({
      display: 'flex',
      gap: theme => theme.spacing(0.8),
    }),
    [],
  );

  return {menuItem};
};
