import {alpha, createTheme, Theme} from '@mui/material/styles';
import HelveticaNeueBold from './fonts/HelveticaNeueBold.otf';
import HelveticaNeueBoldWoff from './fonts/HelveticaNeueBold.woff2';
import HelveticaNeueLight from './fonts/HelveticaNeueLight.otf';
import HelveticaNeueLightWoff from './fonts/HelveticaNeueLight.woff2';
import {Colors, FontSizes, LineHeights} from './variables';

// Default font family
const fontFamilyHelvetica = [
  'HelveticaNeue',
  'Helvetica Neue',
  'Helvetica',
  'Arial',
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Oxygen-Sans',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'sans-serif',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  'Open Sans',
  '-apple-system',
].join(',');

const fontFamilyBembo = fontFamilyHelvetica; //['BemboStd', 'Open Sans', '-apple-system'].join(',');

const theme = createTheme({
  /**
   * Spacing multiplier *
   * Usage:
   * marginRight: theme.spacing(2) // '20px'
   * or
   * marginRight: 2 // '20px'
   * (to be used on layout properties - margins, paddings, gaps, ... -)
   */
  spacing: 10,

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1080, // default: 900
      lg: 1240,
      xl: 1536,
    },
  },

  typography: {
    fontFamily: fontFamilyHelvetica,
    xsmall: {
      fontFamily: fontFamilyHelvetica,
      fontSize: FontSizes.xsmall,
      lineHeight: LineHeights.xsmall,
    },
    small: {
      fontFamily: fontFamilyHelvetica,
      fontSize: FontSizes.Small,
      lineHeight: LineHeights.Small,
    },
    text: {
      fontFamily: fontFamilyHelvetica,
      fontSize: FontSizes.Text,
      lineHeight: LineHeights.Text,
    },
    headline5: {
      fontFamily: fontFamilyHelvetica,
      fontSize: FontSizes.Headline5,
      lineHeight: LineHeights.Headline5,
    },
    headline4: {
      fontFamily: fontFamilyHelvetica,
      fontSize: FontSizes.Headline4,
    },
    headline3: {
      fontFamily: fontFamilyHelvetica,
      fontSize: FontSizes.Headline3,
      lineHeight: LineHeights.Headline3,
    },
    headline2: {
      fontFamily: fontFamilyBembo,
      fontSize: FontSizes.Headline2,
      lineHeight: LineHeights.Headline2,
    },
    headline1: {
      fontFamily: fontFamilyBembo,
      fontSize: FontSizes.Headline1,
      lineHeight: LineHeights.Headline1,
    },
    h1: undefined,
    h2: undefined,
    // Can't be disabled for the mui time picker
    // h3: undefined,
    h4: undefined,
    h5: undefined,
  },

  palette: {
    error: {main: Colors.error},
    success: {main: Colors.success},
    warning: {main: Colors.warning, light: alpha(Colors.warning, 0.2)},

    accent: Colors.accent,
    black: Colors.black,
    gray0: Colors.gray0,
    gray1: Colors.gray1,
    gray2: Colors.gray2,
    gray4: Colors.gray4,
    gray6: Colors.gray6,
    gray8: Colors.gray8,
    white: Colors.white,

    online: Colors.online,
    presence: Colors.presence,
    wt: Colors.wt,
    started: Colors.started,
    pending: Colors.pending,

    white_7: alpha(Colors.white, 0.7),
  },

  boxShadow: {
    gray6_15: `0 8px 24px 0 ${alpha(Colors.gray6, 0.15)}`,
    gray6_30: `0 8px 24px 0 ${alpha(Colors.gray6, 0.3)}`,
    gray6_45: `0 8px 24px 0 ${alpha(Colors.gray6, 0.45)}`,
    gray6_15_table: `0 24px 24px 0 ${alpha(Colors.gray6, 0.15)}`,
    right_table: `2px 0 14px 0 ${alpha(Colors.black, 0.15)}`,
    left_table: `-2px 0 14px 0 ${alpha(Colors.black, 0.15)}`,
    bottom_table: `0 2px 14px 0 ${alpha(Colors.black, 0.15)}`,
    top_table: `0 -2px 14px 0 ${alpha(Colors.black, 0.15)}`,
  },
});

const getButtonCommonStyles = (theme: Theme) => ({
  ...theme.typography.text,
  borderRadius: '4px',
  fontWeight: theme.typography.fontWeightBold,
  textTransform: 'none',
  padding: '16px 20px',
  '&:hover': {
    boxShadow: theme.boxShadow.gray6_45,
  },
});

const extendedTheme = createTheme(theme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html,body,#root {
          height: 100%;
        }
        @font-face {
          font-family: 'HelveticaNeue';
          font-style: normal;
          font-display: swap;
          font-weight: normal;
          src: local('HelveticaNeue'), url(${HelveticaNeueLight}) format('opentype'), url(${HelveticaNeueLightWoff}) format('woff2');
        }
        @font-face {
          font-family: 'HelveticaNeue';
          font-style: normal;
          font-display: swap;
          font-weight: bold;
          src: local('HelveticaNeueBold'), url(${HelveticaNeueBold}) format('opentype'), url(${HelveticaNeueBoldWoff}) format('woff2');
        }
      `,
      /*
      @font-face {
        font-family: 'BemboStd';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: local('BemboStd'), url(${BemboStd}) format('truetype');
      }
      */
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          position: 'unset',
          color: theme.palette.gray6,
          '&.Mui-disabled': {
            color: theme.palette.gray4,
          },
          '&.Mui-error': {
            color: theme.palette.gray6,
          },
          '&.Mui-focused': {
            color: theme.palette.gray6,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: '44px',
          backgroundColor: theme.palette.white,
          border: '1px solid',
          borderColor: theme.palette.gray6,
          padding: '8px 10px',
          borderRadius: 4,
          color: theme.palette.gray6,
          position: 'relative',
          fontSize: 16,
          width: '100%',
          transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
          ]),
          '&:hover': {
            boxShadow: theme.boxShadow.gray6_45,
          },
          '&.Mui-disabled': {
            boxShadow: 'none',
            borderColor: theme.palette.gray4,
          },
          '&.Mui-focus': {
            boxShadow: theme.boxShadow.gray6_45,
            borderColor: theme.palette.gray6,
          },
          '&.Mui-error': {
            borderColor: 'red',
          },
        },
        input: {
          '&::placeholder': {
            color: theme.palette.gray6,
            '&.Mui-disabled': {
              color: theme.palette.gray4,
            },
          },
        },
        multiline: {
          height: 'unset',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: 44,
          whiteSpace: 'nowrap',
        },
      },
      variants: [
        {
          props: {variant: 'primary'},
          style: {
            ...getButtonCommonStyles(theme),
            color: theme.palette.white,
            backgroundColor: theme.palette.black,
            ':hover': {
              backgroundColor: theme.palette.gray8,
            },
            ':disabled': {
              color: theme.palette.gray0,
              backgroundColor: theme.palette.gray4,
            },
          },
        },
        {
          props: {variant: 'secondary'},
          style: {
            ...getButtonCommonStyles(theme),
            color: theme.palette.black,
            backgroundColor: theme.palette.white,
            borderColor: theme.palette.black,
            borderStyle: 'solid',
            borderWidth: '1px',
            ':hover': {
              backgroundColor: theme.palette.gray1,
            },
            ':disabled': {
              color: theme.palette.gray4,
              backgroundColor: theme.palette.gray0,
              borderColor: theme.palette.gray4,
            },
          },
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: '24px',
          backgroundColor: theme.palette.gray6,
        },
        clickable: {
          ':hover': {
            backgroundColor: theme.palette.gray8,
          },
        },
        label: {
          paddingLeft: '10px',
          paddingRight: '10px',
          color: theme.palette.white,
        },
        deleteIcon: {
          color: theme.palette.white,
          ':hover': {color: theme.palette.gray2},
        },
        colorSecondary: {
          backgroundColor: theme.palette.white,
          '& .MuiChip-label': {
            color: theme.palette.black,
            textTransform: 'uppercase',
            fontWeight: theme.typography.fontWeightBold,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.gray6,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginBottom: theme.spacing(1.7),
          paddingBottom: '3px', // To display the indicator overflow
        },
        indicator: {
          backgroundColor: theme.palette.black,
          height: '8px',
          borderRadius: '4px',
          bottom: '-5px',
        },
        scroller: {
          borderBottom: `2px solid ${theme.palette.gray6}`,
          overflow: 'visible !important',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.MuiTab-root': {
            height: '56px',
            color: theme.palette.gray6,
            fontSize: '18px',
            lineHeight: '32px',
            fontFamily: 'HelveticaNeue',
            padding: '0px 0px 24px 0px',
            borderRadius: '2px',
            textTransform: 'none',
          },
          '&.Mui-selected': {
            fontWeight: 'bold',
            color: theme.palette.black,
          },
          '&.Mui-disabled': {
            color: theme.palette.grey[500], // TODO: Handle color
            borderColor: theme.palette.grey[500], // TODO: Handle color
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: theme.palette.black,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: theme.palette.gray6,
          boxShadow: 'unset',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'none',
          },
          flexDirection: 'row-reverse',
          gap: '12px',
          color: theme.palette.gray6,
          '& .Mui-expanded': {
            color: theme.palette.black,
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontSize: theme.typography.small.fontSize,
          lineHeight: theme.typography.small.lineHeight,
          color: theme.palette.black,
        },
      },
    },
  },
});

export default extendedTheme;
