import {useMemo} from 'react';
import {SxProps, Theme} from '@mui/material';

const image: SxProps<Theme> = theme => ({
  width: '300px',
  [theme.breakpoints.up('md')]: {
    width: '600px',
  },
  img: {
    width: '100%',
  },
});

export const useStyles = () => {
  const backdrop = useMemo(
    () => ({
      backgroundImage: 'url(https://picsum.photos/id/46/1920/1080)',
      height: '100%',
    }),
    [],
  );

  const container = useMemo<SxProps<Theme>>(
    () => ({
      backdropFilter: 'blur(40px)',
      backgroundColor: theme => theme.palette.white_7,
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme => theme.spacing(2.5),
    }),
    [],
  );

  const text = useMemo(
    () => ({
      fontSize: '56px',
      color: (theme: Theme) => theme.palette.gray8,
    }),
    [],
  );

  return {backdrop, container, text, image};
};
