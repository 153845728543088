import {useCallback} from 'react';
import * as Sentry from '@sentry/react';
import {BasePaginationParams} from '../../definitions/Base';
import {
  Campaign,
  GetCampaignResponse,
  GetCampaignSellersResponse,
  GetCampaignsResponse,
} from '../../definitions/Campaign';
import {buildQuery} from '../../utils/buildQuery';
import {useAPI} from '../api';
import {GetCampaignHistoryResponse} from './definitions';

/**
 * Hooks for handling campaigns
 */
export const useCampaign = () => {
  const {api} = useAPI();

  /** Get campaign */
  const getCampaign = useCallback(
    async (id: number | string) => {
      try {
        return await api.get<GetCampaignResponse>(`/events/${id}`);
      } catch (error) {
        console.error('[Get Campaign]', error);
        Sentry.captureException(error);
        throw error;
      }
    },
    [api],
  );

  /** Get campaigns */
  const getCampaigns = useCallback(
    async (params?: BasePaginationParams) => {
      try {
        const query = buildQuery({...params});

        return await api.get<GetCampaignsResponse>(`/events${query}`);
      } catch (error) {
        console.error('[Get Campaigns]', error);
        Sentry.captureException(error);
        throw error;
      }
    },
    [api],
  );

  /** Add new campaign */
  const addCampaign = useCallback(
    async (payload: Campaign) => {
      try {
        return await api.post('/events', payload);
      } catch (error) {
        console.error('[Add Campaign]', error);
        Sentry.captureException(error);
        throw error;
      }
    },
    [api],
  );

  /** Get unpublished campaigns */
  const getUnpublishedCampaigns = useCallback(
    async (params?: BasePaginationParams) => {
      try {
        const query = buildQuery({...params});
        return await api.get<GetCampaignsResponse>(
          `/events/unpublished${query}`,
        );
      } catch (error) {
        console.error('[Get Unpublished Campaigns]', error);
        Sentry.captureException(error);
        throw error;
      }
    },
    [api],
  );

  /** Get history campaigns */
  const getHistoryCampaigns = useCallback(
    async (params?: BasePaginationParams) => {
      try {
        const query = buildQuery({...params});
        return await api.get<GetCampaignsResponse>(`/events/history${query}`);
      } catch (error) {
        console.error('[Get History Campaigns]', error);
        Sentry.captureException(error);
        throw error;
      }
    },
    [api],
  );

  /** Update campaign */
  const updateCampaign = useCallback(
    async ({id, payload}: {id: number; payload: Campaign}) => {
      try {
        return await api.put(`/events/${id}`, payload);
      } catch (error) {
        console.error('[Update Campaign]', error);
        Sentry.captureException(error);
        throw error;
      }
    },
    [api],
  );

  /** Patch campaign */
  const _patchCampaign = useCallback(
    async ({id, payload}: {id: number; payload: Partial<Campaign>}) => {
      try {
        // it became a post method to support Azure environment
        return await api.post(`/events/${id}/patch`, payload);
      } catch (error) {
        console.error('[Patch Campaign]', error);
        Sentry.captureException(error);
        throw error;
      }
    },
    [api],
  );

  /** Unpublish a campaign */
  const unpublishCampaign = useCallback(
    async (id: number) => {
      try {
        return await _patchCampaign({id, payload: {is_publish: false}});
      } catch (error) {
        console.error('[Unpublish Campaign]', error);
        Sentry.captureException(error);
        throw error;
      }
    },
    [_patchCampaign],
  );

  /** Publish a campaign */
  const publishCampaign = useCallback(
    async (id: number) => {
      try {
        return await _patchCampaign({id, payload: {is_publish: true}});
      } catch (error) {
        console.error('[Publish Campaign]', error);
        Sentry.captureException(error);
        throw error;
      }
    },
    [_patchCampaign],
  );

  /** Delete a campaign */
  const deleteCampaign = useCallback(
    async (id: number) => {
      try {
        return await api.delete(`/events/${id}`);
      } catch (error) {
        console.error('[Delete Campaign]', error);
        Sentry.captureException(error);
        throw error;
      }
    },
    [api],
  );

  /** Get a list of linked sellers */
  const getLinkedSellers = useCallback(
    async (id: string) => {
      try {
        return await api.get<GetCampaignSellersResponse>(
          `/events/${id}/sellers`,
        );
      } catch (error) {
        console.error('[Get Campaign Sellers]', error);
        Sentry.captureException(error);
        throw error;
      }
    },
    [api],
  );

  /**
   * Get campaign history
   *
   * @param id - Campaign id
   *
   * @returns Campaign history
   * @throws Error
   * */
  const getCampaignHistory = useCallback(
    async (
      id: number | string,
      filters?: {action?: string; fulltext?: string},
    ) => {
      try {
        const normalizedFilters: {action?: string; fulltext?: string} = {
          action: filters?.action === 'ALL' ? undefined : filters?.action,
          fulltext: filters?.fulltext === '' ? undefined : filters?.fulltext,
        };

        return await api.get<GetCampaignHistoryResponse>(
          `calendar/${id}/appointments/history`,
          {
            params: normalizedFilters,
          },
        );
      } catch (error) {
        console.error('[Get Campaign History]', error);
        Sentry.captureException(error);
        throw error;
      }
    },
    [api],
  );

  return {
    getCampaign,
    getCampaigns,
    getUnpublishedCampaigns,
    getHistoryCampaigns,
    addCampaign,
    updateCampaign,
    unpublishCampaign,
    publishCampaign,
    deleteCampaign,
    getLinkedSellers,
    getCampaignHistory,
  };
};
