import {useCallback, useMemo} from 'react';
import {AuthRoutes} from '../../navigation';

export const useSSO = () => {
  const redirectURI = `${
    process.env.REACT_APP_KEYCLOACK_AUTH_REDIRECT_URI as string
  }${AuthRoutes.MAIN}`;

  const logoutUrl = (process.env.REACT_APP_KEYCLOACK_LOGOUT_URL as string)
    .replace(/:realm/g, process.env.REACT_APP_KEYCLOACK_AUTH_REALM as string)
    .replace(/:redirect_uri/g, encodeURIComponent(redirectURI));

  const SSOUri = useMemo(() => {
    return (process.env.REACT_APP_KEYCLOACK_AUTH_BASE_URL as string)
      .replace(/:realm/g, process.env.REACT_APP_KEYCLOACK_AUTH_REALM as string)
      .replace(/:redirect_uri/g, encodeURIComponent(redirectURI));
  }, [redirectURI]);

  const goToSSO = useCallback(async () => {
    window.location.assign(SSOUri);
  }, [SSOUri]);

  return {goToSSO, redirectURI, logoutUrl};
};
