import {Account, UserRole} from '../definitions/Users';

export const getFullName = (name?: string, surname?: string) =>
  `${name || ''} ${surname || ''}`.trim();

export const getRoleSuffix = (user: Account): string => {
  switch (user.role) {
    case UserRole.SHOWROOM_MANAGER:
      return 'SM';
    case UserRole.SALES_MANAGER:
      return 'AM';
    case UserRole.SELLER:
      return 'S';
    case UserRole.RECEPTIONIST:
      return 'R';
    case UserRole.READ_ONLY:
      return 'RO';
    case UserRole.BUYER:
      return 'B';
  }
};
