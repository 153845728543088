import {useCallback, useMemo} from 'react';
import {SxProps, Theme} from '@mui/material';
import {ColumnStyleProps, TableStyleProps} from './interfaces';

const container: SxProps<Theme> = {
  boxShadow: theme => theme.boxShadow.gray6_15,
  borderRadius: '4px',
};

const tableHead: SxProps<Theme> = {
  position: 'sticky',
  top: 0,
  zIndex: 12,
  boxShadow: theme => theme.boxShadow.bottom_table,
};

const tableRow: SxProps<Theme> = {
  '&:hover': {
    cursor: 'pointer',
  },
};

const stickyTableCell: SxProps<Theme> = {
  position: 'sticky',
  backgroundColor: theme => theme.palette.white,
  zIndex: 4,
  boxShadow: theme => theme.boxShadow.gray6_15_table,
};

const tablePagination: SxProps<Theme> = {
  boxShadow: theme => theme.boxShadow.gray6_15,
  position: 'sticky',
  zIndex: 12,
  '& .MuiInputBase-root': {
    maxWidth: '100px',
  },
};

export const useStyles = ({tableMaxHeight}: TableStyleProps) => {
  const tableContainer = useMemo<SxProps<Theme>>(
    () => ({
      maxHeight: tableMaxHeight,
    }),
    [tableMaxHeight],
  );

  const tableCell = useCallback<(props: ColumnStyleProps) => SxProps<Theme>>(
    ({sticky, minWidth}) => ({
      borderBottom: 'unset',
      ...(sticky && stickyTableCell),
      ...(sticky === 'left' && {
        left: 0,
        '&.MuiTableCell-head': {
          boxShadow: theme => theme.boxShadow.right_table,
        },
      }),
      ...(sticky === 'right' && {
        right: 0,
        '&.MuiTableCell-head': {
          boxShadow: theme => theme.boxShadow.left_table,
        },
      }),
      minWidth,
    }),
    [],
  );

  return {
    container,
    tableContainer,
    tableHead,
    tableRow,
    tableCell,
    tablePagination,
  };
};
