import {useCallback} from 'react';
import * as Sentry from '@sentry/react';
import {useQueryClient} from '@tanstack/react-query';
import {useDispatch, useSelector} from 'react-redux';
import {useSSO} from '../../hooks/sso';
import {_api as api} from '../api';
import {LoginFail, LoginLoading, LoginSuccess, Logout} from './actions';
import {
  selectorError,
  selectorIsFetching,
  selectorIsLoggedIn,
  selectorMsToken,
  selectorRefreshToken,
  selectorToken,
} from './selectors';
import {AuthenticationResponse, AuthenticationState} from './types.d';

const keycloakSettings = {
  client_id: 'versace-sso',
  grant_type: 'authorization_code',
  URL_REALM: 'versace',
  URL_TOKEN: (process.env.REACT_APP_KEYCLOACK_BASE_URL as string).replace(
    /:realm/g,
    process.env.REACT_APP_KEYCLOACK_AUTH_REALM as string,
  ),
};

export const useAuthentication = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const {redirectURI, logoutUrl} = useSSO();

  const isLoggedIn = useSelector<
    {authentication: AuthenticationState},
    boolean
  >(selectorIsLoggedIn);

  const token = useSelector<
    {authentication: AuthenticationState},
    string | undefined
  >(selectorToken);

  const refreshTokenFromStore = useSelector<
    {authentication: AuthenticationState},
    string | undefined
  >(selectorRefreshToken);

  const msToken = useSelector<
    {authentication: AuthenticationState},
    string | undefined
  >(selectorMsToken);

  const error = useSelector<
    {authentication: AuthenticationState},
    string | undefined
  >(selectorError);

  const isFetching = useSelector<
    {authentication: AuthenticationState},
    boolean
  >(selectorIsFetching);

  const saveAuth = useCallback(
    ({token, refreshToken, msToken}: AuthenticationResponse) => {
      try {
        localStorage.setItem('auth', token);
        localStorage.setItem('auth_refresh', refreshToken);
        localStorage.setItem('ms_token', msToken);
      } catch (e) {
        Sentry.captureException(e);
        throw e;
      }
    },
    [],
  );

  const getAuth = useCallback(() => {
    try {
      const token = localStorage.getItem('auth');
      const refreshToken = localStorage.getItem('auth_refresh');
      const msToken = localStorage.getItem('ms_token');
      if (token && refreshToken && msToken) {
        dispatch(LoginSuccess({token, refreshToken, msToken}));
      }
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }
  }, [dispatch]);

  const removeAuth = useCallback(() => {
    try {
      localStorage.removeItem('auth');
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }
  }, []);

  const login = useCallback(
    async (code: string) => {
      try {
        dispatch(LoginLoading());

        const body = [
          ['client_id', keycloakSettings.client_id].join('='),
          ['grant_type', keycloakSettings.grant_type].join('='),
          ['code', code].join('='),
          ['redirect_uri', redirectURI].join('='),
        ].join('&');

        try {
          const {data} = await api({
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            data: body,
            url: keycloakSettings.URL_TOKEN!,
          });

          const {
            access_token: msToken,
            refresh_token: refreshToken,
            // id_token: token,
          } = data;

          saveAuth({token: msToken, refreshToken, msToken});
          dispatch(LoginSuccess({token: msToken, refreshToken, msToken}));

          return msToken;
        } catch (e) {
          // @TODO: Sentry
          console.error(e);
          throw e;
        }
      } catch (error) {
        if (error instanceof Error) {
          dispatch(LoginFail(error));
          Sentry.captureException(error);
          throw error;
        }
      }
    },
    [dispatch, redirectURI, saveAuth],
  );

  const logout = useCallback(async () => {
    try {
      const refreshToken = localStorage.getItem('auth_refresh');

      if (refreshToken) {
        await api({
          url: logoutUrl,
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          data: [
            ['client_id', keycloakSettings.client_id].join('='),
            ['refresh_token', refreshToken].join('='),
          ].join('&'),
        });
      }

      removeAuth();
      dispatch(Logout());
      // Clear all TanStack Query caches
      queryClient.clear();
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }, [removeAuth, dispatch, queryClient, logoutUrl]);

  const refreshLogin = useCallback(async () => {
    try {
      dispatch(LoginLoading());
      const {data} = await api.post('/auth/refresh_token', {
        refresh_token: refreshTokenFromStore,
      });
      const {
        access_token: msToken,
        refresh_token: refreshToken,
        id_token: token,
      } = data;
      saveAuth({token, refreshToken, msToken});
      dispatch(LoginSuccess({token, refreshToken, msToken}));
    } catch (error) {
      logout();
      if (error instanceof Error) {
        dispatch(LoginFail(error));
        Sentry.captureException(error);
        throw error;
      }
    }
  }, [dispatch, logout, refreshTokenFromStore, saveAuth]);

  return {
    isLoggedIn,
    token,
    refreshToken: refreshTokenFromStore,
    msToken,
    error,
    login,
    refreshLogin,
    getAuth,
    isFetching,
    logout,
    removeAuth,
  };
};
