import React from 'react';
import IcomoonReact from 'icomoon-react';
import iconSet from '../../../themes/assets/selection.json';
import {IconSVGProps} from './interfaces';

const IconSVG = ({
  className,
  icon,
  size,
  color = 'currentcolor',
  ...props
}: IconSVGProps): JSX.Element => (
  <IcomoonReact
    {...props}
    className={className}
    iconSet={iconSet}
    color={color}
    size={size}
    icon={icon}
  />
);

export default React.memo(IconSVG);
