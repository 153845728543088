import {useMemo} from 'react';
import {SxProps, Theme} from '@mui/material';

export const useStyles = () => {
  const select = useMemo<SxProps<Theme>>(
    () => ({
      p: 0,
      '& .MuiSelect-select': {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme => theme.spacing(1),
      },
      '& .MuiSelect-icon': {
        top: 'unset',
        right: '10px',
      },
    }),
    [],
  );

  return {select};
};
