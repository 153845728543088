import React from 'react';
import {Box, Button} from '@mui/material';
import {Breadcrumb} from '../../atoms/Breadcrumb';
import {IconSVG} from '../../atoms/IconSVG';
import {Text} from '../../atoms/Text';
import {PageHeadProps} from './interfaces';
import {useStyles} from './styles';

const PageHead = ({
  breadcrumbs,
  title,
  subtitle,
  customActions,
  actions,
}: PageHeadProps) => {
  const styles = useStyles();

  return (
    <Box sx={styles.container}>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <Box sx={styles.infoContainer}>
        <Box sx={styles.labelContainer}>
          <Text variant="headline1" bold>
            {title}
          </Text>
          <Text variant="headline4" bold>
            {subtitle}
          </Text>
        </Box>
        <Box sx={styles.subtitleContainer}>
          {customActions && (
            <Box sx={styles.customActions}>{customActions}</Box>
          )}
          {actions && (
            <Box sx={styles.actions}>
              {actions.map(({label, icon, onClick}) => (
                <Button
                  key={label}
                  variant="primary"
                  onClick={onClick}
                  endIcon={<IconSVG icon={icon} size={24} />}>
                  {label}
                </Button>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(PageHead);
