import React from 'react';
import {Breadcrumbs as BreadcrumbsMUI, Link as LinkMUI} from '@mui/material';
import {Link} from 'react-router-dom';
import {IconSVG} from '../IconSVG';
import {Text} from '../Text';
import {BreadcrumbsProps} from './interface';
import {useStyles} from './styles';

const Breadcrumbs = ({breadcrumbs}: BreadcrumbsProps) => {
  const styles = useStyles();

  return (
    <BreadcrumbsMUI
      aria-label="breadcrumb"
      separator={<IconSVG icon="arrow_right" size={14} />}>
      {breadcrumbs.map(({label, to}) => {
        if (to) {
          return (
            <LinkMUI
              key={label}
              underline="always"
              color="inherit"
              component={Link}
              to={to}>
              {label}
            </LinkMUI>
          );
        } else {
          return (
            <Text key={label} variant="small" sx={styles.disabledBreadcrumb}>
              {label}
            </Text>
          );
        }
      })}
    </BreadcrumbsMUI>
  );
};

export default React.memo(Breadcrumbs);
