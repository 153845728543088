import {useCallback} from 'react';
import {generatePath, useNavigate} from 'react-router-dom';
import {AuthRoutes} from './routes';

export const useNavigation = () => {
  const navigate = useNavigate();

  const goToPath = useCallback(
    (path: AuthRoutes) => {
      navigate(path);
    },
    [navigate],
  );

  const goToMain = useCallback(() => {
    navigate(AuthRoutes.MAIN);
  }, [navigate]);

  const goToCalendarDetail = useCallback(
    (campaignId: number) => {
      navigate(
        generatePath(AuthRoutes.CALENDAR_DETAIL, {
          campaignId: String(campaignId),
        }),
      );
    },
    [navigate],
  );

  const goToAppointmentsManagement = useCallback(
    (campaignId?: number | string) => {
      navigate(
        generatePath(AuthRoutes.APPOINTMENTS_MANAGEMENT, {
          campaignId: String(campaignId),
        }),
      );
    },
    [navigate],
  );

  const goToCampaignHistory = useCallback(
    (campaignId?: number | string) => {
      navigate(
        generatePath(AuthRoutes.CAMPAIGN_HISTORY, {
          campaignId: String(campaignId),
        }),
      );
    },
    [navigate],
  );

  const goToSendCampaignInvitation = useCallback(
    (campaignId?: number | string) => {
      navigate(
        generatePath(AuthRoutes.SEND_CAMPAIGN_INVITATION, {
          campaignId: String(campaignId),
        }),
      );
    },
    [navigate],
  );

  const goToAppointmentsRescheduling = useCallback(
    ({
      campaignId,
      appointmentId,
    }: {
      campaignId?: number | string;
      appointmentId?: number | string;
    }) => {
      navigate(
        generatePath(AuthRoutes.APPOINTMENTS_RESCHEDULING, {
          campaignId: String(campaignId),
          appointmentId: String(appointmentId),
        }),
      );
    },
    [navigate],
  );

  const goToAppointmentRequest = useCallback(
    (state: string) => {
      navigate(`${AuthRoutes.APPOINTMENT_REQUEST}?state=${state}`);
    },
    [navigate],
  );

  const goToAppointmentRequestSuccess = useCallback(
    (state: string) => {
      navigate(`${AuthRoutes.APPOINTMENT_REQUEST_SUCCESS}?state=${state}`);
    },
    [navigate],
  );

  const goToAppointmentRescheduleSuccess = useCallback(
    (state: string) => {
      navigate(`${AuthRoutes.APPOINTMENT_RESCHEDULE_SUCCESS}?state=${state}`);
    },
    [navigate],
  );

  return {
    goToPath,
    goToMain,
    goToCalendarDetail,
    goToAppointmentsManagement,
    goToCampaignHistory,
    goToSendCampaignInvitation,
    goToAppointmentsRescheduling,
    goToAppointmentRequest,
    goToAppointmentRequestSuccess,
    goToAppointmentRescheduleSuccess,
  };
};
