import {useMemo} from 'react';
import {SxProps, Theme} from '@mui/material';

export const useStyles = () => {
  const container = useMemo<SxProps<Theme>>(
    () => ({marginTop: theme => theme.spacing(1.6)}),
    [],
  );

  const infoContainer = useMemo<SxProps<Theme>>(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: theme => theme.spacing(1.6),
    }),
    [],
  );

  const subtitleContainer = useMemo<SxProps<Theme>>(
    () => ({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: theme => theme.spacing(1.6),
      marginTop: theme => theme.spacing(0.4),
    }),
    [],
  );

  const customActions = useMemo<SxProps<Theme>>(
    () => ({
      display: 'flex',
      gap: theme => theme.spacing(1.6),
    }),
    [],
  );

  const actions = useMemo<SxProps<Theme>>(
    () => ({
      display: 'flex',
      gap: theme => theme.spacing(1.6),
    }),
    [],
  );

  const labelContainer = useMemo<SxProps<Theme>>(
    () => ({
      display: 'flex',
      alignItems: 'center',
      gap: theme => theme.spacing(2.4),
    }),
    [],
  );

  return {
    container,
    infoContainer,
    subtitleContainer,
    customActions,
    actions,
    labelContainer,
  };
};
