import React, {useMemo} from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Tooltip,
} from '@mui/material';
import {zeroWidthSpace} from '../../../utils/zeroWidthSpace';
import {IconSVG} from '../../atoms/IconSVG';
import {Text} from '../../atoms/Text';
import {FormFieldProps} from './interfaces';
import {useStyles} from './styles';

const FormField = ({
  disabled,
  error,
  warning,
  helperText = zeroWidthSpace,
  label = zeroWidthSpace,
  tooltip,
  required,
  children,
}: FormFieldProps) => {
  const styles = useStyles();

  const formHelperText = useMemo(
    () => (
      <>
        {error && (
          <div>
            <IconSVG icon="alert" size={16} />
          </div>
        )}
        <Text as="span" variant="small">
          {helperText}
        </Text>
        {!error && warning && (
          <Box sx={styles.warningBox}>
            <div>
              <IconSVG icon="alert" size={16} />
            </div>
            <Text as="span" variant="small">
              {warning}
            </Text>
          </Box>
        )}
      </>
    ),
    [error, helperText, styles.warningBox, warning],
  );

  return (
    <FormControl variant="standard" {...{disabled, error, required}} fullWidth>
      <InputLabel shrink>
        <Text variant="small" component="span">
          {label}
        </Text>
        {tooltip && (
          <Tooltip title={tooltip} placement="right" arrow>
            <IconButton
              size="small"
              sx={styles.tooltip}
              disableRipple
              disableFocusRipple>
              <IconSVG icon="info" size={20} />
            </IconButton>
          </Tooltip>
        )}
      </InputLabel>
      {children}
      <FormHelperText sx={styles.helperTextContainer}>
        {formHelperText}
      </FormHelperText>
    </FormControl>
  );
};

export default React.memo(FormField);
