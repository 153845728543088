import {useMemo} from 'react';
import {SxProps, Theme} from '@mui/material';

export const useStyles = () => {
  const disabledBreadcrumb = useMemo<SxProps<Theme>>(
    () => ({color: theme => theme.palette.gray6}),
    [],
  );

  return {disabledBreadcrumb};
};
