import React, {FunctionComponent, useEffect, useState} from 'react';
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import {useTranslations} from '@vidiemme/react-i18n';
import {LoaderSplash} from '../components/atoms/LoaderSplash';
import AppointmentRequest from '../components/pages/AppointmentRequest';
import AppointmentRequestSuccess from '../components/pages/AppointmentRequestSuccess';
import AppointmentReschedule from '../components/pages/AppointmentReschedule';
import AppointmentRescheduleSuccess from '../components/pages/AppointmentRescheduleSuccess';
import AppointmentsManagement from '../components/pages/AppointmentsManagement';
import AppointmentsRescheduling from '../components/pages/AppointmentsRescheduling';
import AuthCallback from '../components/pages/AuthCallback';
import CalendarDetail from '../components/pages/CalendarDetail';
import {CampaignHistory} from '../components/pages/CampaignHistory';
import CampaignList from '../components/pages/CampaignList';
import CampaignManagement from '../components/pages/CampaignManagement';
import CookiePolicy from '../components/pages/CookiePolicy';
import Homepage from '../components/pages/Homepage';
import Login from '../components/pages/Login';
import NoPermissions from '../components/pages/NoPermissions';
import NoRole from '../components/pages/NoRole';
import PageNotFound from '../components/pages/PageNotFound';
import PrivacyPolicy from '../components/pages/PrivacyPolicy';
import SendCampaignInvitation from '../components/pages/SendCampaignInvitation';
import UserManagementList from '../components/pages/UserManagementList';
import {UserRole} from '../definitions/Users';
import {useToast} from '../hooks';
import {useAuthentication} from '../store/authentication';
import Authenticated from './middleware/Authenticated';
import Guest from './middleware/Guest';
import Loadable from './middleware/Loadable';
import LoadableRoute from './middleware/LoadableRoute';
import WithoutRole from './middleware/WithoutRole';
import WithRole from './middleware/WithRole';
import {AnonymousRoutes, AuthRoutes} from './routes';

export const Navigation: FunctionComponent = () => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const {getAuth} = useAuthentication();
  const {showErrorToast} = useToast();
  const {t} = useTranslations();

  useEffect(() => {
    try {
      getAuth();
    } catch (e) {
      showErrorToast({message: t('Authentication.authError')});
    }
    setInitialized(true);
  }, [setInitialized, getAuth, t, showErrorToast]);

  if (initialized) {
    return (
      <Router>
        <Routes>
          {/* @TODO: check if route exists */}

          {/* Add Suspense Loader for code splitting */}
          <Route element={<Loadable />}>
            {/* Add all guest routes as child of this middleware route */}
            <Route element={<Guest />}>
              <Route path={AnonymousRoutes.LOGIN} element={<Login />} />
              <Route
                path={AnonymousRoutes.VERSACE_AUTH}
                element={<AuthCallback />}>
                {/* Required to manage unexpected SSO redirects */}
                <Route path="*" element={<AuthCallback />} />
              </Route>
            </Route>

            {/* Add all authenticated routes as child of this middleware route */}
            <Route element={<Authenticated />}>
              <Route path={AuthRoutes.MAIN} element={<Homepage />}>
                <Route element={<LoadableRoute />}>
                  {/* CAMPAIGN MANAGEMENT */}
                  <Route
                    element={
                      <WithRole
                        requiredRoles={[
                          UserRole.SHOWROOM_MANAGER,
                          UserRole.SALES_MANAGER,
                          UserRole.SELLER,
                          UserRole.RECEPTIONIST,
                          UserRole.READ_ONLY,
                        ]}
                      />
                    }>
                    <Route
                      path={AuthRoutes.CAMPAIGN_MANAGEMENT}
                      element={<CampaignManagement />}
                    />
                    <Route
                      path={AuthRoutes.CAMPAIGN_LIST}
                      element={<CampaignList />}
                    />
                    <Route
                      path={AuthRoutes.CALENDAR_DETAIL}
                      element={<CalendarDetail />}
                    />
                    <Route
                      element={
                        <WithRole
                          requiredRoles={[
                            UserRole.SHOWROOM_MANAGER,
                            UserRole.SALES_MANAGER,
                          ]}
                        />
                      }>
                      <Route
                        path={AuthRoutes.APPOINTMENTS_MANAGEMENT}
                        element={<AppointmentsManagement />}
                      />
                      <Route
                        path={AuthRoutes.SEND_CAMPAIGN_INVITATION}
                        element={<SendCampaignInvitation />}
                      />
                      <Route
                        path={AuthRoutes.APPOINTMENTS_RESCHEDULING}
                        element={<AppointmentsRescheduling />}
                      />
                      <Route
                        path={AuthRoutes.CAMPAIGN_HISTORY}
                        element={<CampaignHistory />}
                      />
                    </Route>
                  </Route>

                  {/* USER MANAGEMENT */}
                  <Route
                    element={
                      <WithRole
                        requiredRoles={[
                          UserRole.SHOWROOM_MANAGER,
                          UserRole.SALES_MANAGER,
                        ]}
                      />
                    }>
                    <Route
                      path={AuthRoutes.USER_MANAGEMENT}
                      element={<UserManagementList />}
                    />
                  </Route>

                  {/* APPOINTMENT BUYER */}
                  <Route
                    element={<WithRole requiredRoles={[UserRole.BUYER]} />}>
                    <Route
                      path={AuthRoutes.APPOINTMENT_REQUEST}
                      element={<AppointmentRequest />}
                    />
                    <Route
                      path={AuthRoutes.APPOINTMENT_RESCHEDULE}
                      element={<AppointmentReschedule />}
                    />
                    <Route
                      path={AuthRoutes.APPOINTMENT_REQUEST_SUCCESS}
                      element={<AppointmentRequestSuccess />}
                    />
                    <Route
                      path={AuthRoutes.APPOINTMENT_RESCHEDULE_SUCCESS}
                      element={<AppointmentRescheduleSuccess />}
                    />
                  </Route>

                  <Route
                    element={<WithRole requiredRoles={[UserRole.BUYER]} />}>
                    <Route
                      path={AuthRoutes.NO_PERMISSIONS}
                      element={<NoPermissions />}
                    />
                  </Route>
                  <Route element={<WithoutRole />}>
                    <Route path={AuthRoutes.NO_ROLE} element={<NoRole />} />
                  </Route>
                </Route>

                {/* Handle all non matching routes for authenticated users */}
                <Route path="*" element={<PageNotFound />} />
              </Route>
            </Route>

            {/* PRIVACY */}
            <Route
              path={AuthRoutes.PRIVACY_POLICY}
              element={<PrivacyPolicy />}
            />

            {/* COOKIE */}
            <Route path={AuthRoutes.COOKIE_POLICY} element={<CookiePolicy />} />

            {/* Handle all non matching routes */}
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </Router>
    );
  } else {
    return <LoaderSplash />;
  }
};
