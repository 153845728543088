export enum Colors {
  error = '#DA1640',
  success = '#5CC78B',
  warning = '#FFB82F',

  accent = '#AA555A',
  black = '#121212',
  gray0 = '#F4F4F4',
  gray1 = '#E6E6E6',
  gray2 = '#D7D7D7',
  gray4 = '#BFBFBF',
  gray6 = '#747474',
  gray8 = '#555555',
  white = '#FFFFFF',

  online = '#9393A5',
  presence = '#D17B88',
  wt = '#7BBE6A',
  started = '#72A04B',
  pending = '#FF8427',
}

export enum FontSizes {
  xsmall = '10px',
  Small = '14px',
  Text = '16px',
  Headline5 = '18px',
  Headline4 = '21px',
  Headline3 = '26px',
  Headline2 = '31px',
  Headline1 = '37px',
}

export enum LineHeights {
  xsmall = '16px',
  Small = '20px',
  Text = '28px',
  Headline5 = '32px',
  Headline3 = '40px',
  Headline2 = '48px',
  Headline1 = '52px',
}
