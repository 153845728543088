import React from 'react';
import {
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Table as TableMUI,
  TablePagination,
  TableRow,
} from '@mui/material';
import {Text} from '../../atoms/Text';
import {typedMemo} from '../../hocs/TypedMemo';
import {BaseRow, TableProps} from './interfaces';
import {useStyles} from './styles';
import {TableActions} from './TableActions';

const Table = <Row extends BaseRow, ColumnId extends keyof Row>({
  columns,
  rows,
  rowsPerPage,
  rowsPerPageOptions = [-1],
  page,
  count = -1,
  onPageChange,
  onRowsPerPageChange,
  tableMaxHeight,
  disablePagination = false,
}: TableProps<Row, ColumnId> & {disablePagination?: boolean}) => {
  const styles = useStyles({tableMaxHeight});

  return (
    <Box sx={styles.container}>
      <TableContainer sx={styles.tableContainer}>
        <TableMUI stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow sx={styles.tableHead}>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  sx={styles.tableCell({
                    sticky: column.sticky,
                    minWidth: column.minWidth,
                  })}>
                  <Text bold whiteSpace="nowrap">
                    {column.label}
                  </Text>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows?.map(row => (
              <TableRow sx={styles.tableRow} hover tabIndex={-1} key={row.id}>
                {columns.map(column => {
                  const content =
                    column.actions && row.actions ? (
                      <TableActions
                        columnActions={column.actions}
                        rowId={row.id}
                        rowIsEditable={row.isEditable}
                      />
                    ) : (
                      row[column.id] && (
                        <Text variant="small">{String(row[column.id])}</Text>
                      )
                    );

                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      onClick={
                        // Disable onclick if column has action button
                        column.actions ? undefined : () => row.onClick?.(row.id)
                      }
                      sx={styles.tableCell({
                        sticky: column.sticky,
                        minWidth: column.minWidth,
                      })}>
                      {content}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </TableMUI>
      </TableContainer>
      {!disablePagination && (
        <TablePagination
          sx={styles.tablePagination}
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
    </Box>
  );
};

export default typedMemo(Table);
