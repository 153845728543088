import React from 'react';
import {IconButton, Menu, MenuItem} from '@mui/material';
import {IconSVG} from '../../../atoms/IconSVG';
import {TableActionsProps} from './interfaces';
import {useStyles} from './styles';

const TableActions = ({
  rowId,
  columnActions,
  rowIsEditable = true,
}: TableActionsProps) => {
  const styles = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="row actions"
        id="icon-button"
        aria-controls={open ? 'actions-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <IconSVG icon="action" size={24} />
      </IconButton>
      <Menu
        id="actions-menu"
        MenuListProps={{
          'aria-labelledby': 'icon-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {columnActions?.map(({id, onClick, icon, label, isEdit}) => {
          if (isEdit && !rowIsEditable) {
            return null;
          }

          const handleClick = () => {
            handleClose();
            onClick(rowId);
          };

          return (
            <MenuItem key={id} onClick={handleClick} sx={styles.menuItem}>
              <IconSVG icon={icon} size={24} />
              {label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default React.memo(TableActions);
