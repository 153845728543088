import React, {useCallback, useMemo, useRef, useState} from 'react';
import {Box, Grid, InputBase, InputLabel} from '@mui/material';
import {useQuery} from '@tanstack/react-query';
import dayjs from 'dayjs';
import {generatePath, useParams} from 'react-router-dom';
import {useTranslations} from '@vidiemme/react-i18n';
import {AuthRoutes} from '../../../navigation';
import {GetCampaignHistoryResponse} from '../../../store/campaign/definitions';
import {useCampaign} from '../../../store/campaign/hooks';
import {useUser} from '../../../store/user/hooks';
import {getFullName} from '../../../utils/getFullName';
import {BreadcrumbsProps} from '../../atoms/Breadcrumb/interface';
import {IconSVG} from '../../atoms/IconSVG';
import {Text} from '../../atoms/Text';
import {Select} from '../../molecules/Select';
import {Table} from '../../molecules/Table';
import {Column} from '../../molecules/Table/interfaces';
import {PageHead} from '../../organisms/PageHead';
import {useStyles} from '../SendCampaignInvitation/styles';

const CampaignHistory = () => {
  const {isShowroomManager} = useUser();
  const {campaignId} = useParams();
  const {t} = useTranslations();
  const styles = useStyles();
  const {getCampaignHistory} = useCampaign();

  const breadcrumbs = useMemo<BreadcrumbsProps['breadcrumbs']>(
    () => [
      isShowroomManager
        ? {
            label: t('CampaignManagement.title'),
            to: AuthRoutes.CAMPAIGN_MANAGEMENT,
          }
        : {label: t('CampaignList.title'), to: AuthRoutes.CAMPAIGN_LIST},
      {
        label: t('Calendar.title'),
        to: generatePath(AuthRoutes.CALENDAR_DETAIL, {
          campaignId: String(campaignId),
        }),
      },
      {label: t('CampaignHistory.title')},
    ],
    [campaignId, isShowroomManager, t],
  );

  const [filterAction, setFilterAction] = useState<string>('ALL');
  const filterOptions = useMemo(
    () => [
      {value: 'ALL', label: t('CampaignHistory.filters.action.options.all')},
      {
        value: 'CREATED',
        label: t('CampaignHistory.filters.action.options.created'),
      },
      {
        value: 'UPDATED',
        label: t('CampaignHistory.filters.action.options.updated'),
      },
      {
        value: 'DELETED',
        label: t('CampaignHistory.filters.action.options.deleted'),
      },
    ],
    [t],
  );

  const [fulltextValue, setFulltextValue] = useState('');
  const [fulltextFilter, setFulltextFilter] = useState('');

  // To debounce the fulltext filter query request
  const timerFulltextDebounce = useRef<NodeJS.Timeout | null>(null);
  const handleFulltextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (timerFulltextDebounce.current) {
        clearTimeout(timerFulltextDebounce.current);
      }

      setFulltextValue(e.target.value);

      timerFulltextDebounce.current = setTimeout(() => {
        setFulltextFilter(e.target.value);
      }, 750);
    },
    [],
  );

  const performGetCampaignHistory = useCallback(async () => {
    return await getCampaignHistory(campaignId!, {
      action: filterAction,
      fulltext: fulltextFilter,
    });
  }, [campaignId, getCampaignHistory, filterAction, fulltextFilter]);

  const {data} = useQuery(
    ['campaign-history', campaignId, filterAction, fulltextFilter],
    performGetCampaignHistory,
    {
      enabled: !!campaignId,
    },
  );

  const normalizedCompanyHistory = useMemo(() => {
    const campaignHistory = (data?.data || []) as GetCampaignHistoryResponse[];

    return campaignHistory.map(
      ({
        store: {store_name},
        day: {date},
        start_time,
        contact_buyer,
        buyers,
        editor,
        created_at,
        ...rest
      }) => ({
        store__store_name: store_name,
        appointment_date: dayjs(`${date}T${start_time}`).format(
          'DD/MM/YYYY HH:mm',
        ),
        stringified_buyers: [
          getFullName(contact_buyer.first_name, contact_buyer.last_name),
        ]
          .concat(
            buyers.map(({first_name, last_name}) =>
              getFullName(first_name, last_name),
            ),
          )
          .join(', '),
        stringified_editor: getFullName(editor.first_name, editor.last_name),
        buyers,
        editor,
        editedOn: dayjs(created_at).format('DD/MM/YYYY HH:mm'),
        ...rest,
      }),
    );
  }, [data?.data]);

  const columns: Column<any>[] = useMemo(() => {
    return [
      {
        id: 'appointment_id',
        label: t('CampaignHistory.list.columns.id'),
        sticky: 'left',
      },
      {
        id: 'store__store_name',
        label: t('CampaignHistory.list.columns.store'),
      },
      {
        id: 'appointment_date',
        label: t('CampaignHistory.list.columns.appointmentDate'),
      },
      {
        id: 'stringified_buyers',
        label: t('CampaignHistory.list.columns.buyers'),
      },
      {
        id: 'action',
        label: t('CampaignHistory.list.columns.action'),
      },
      {
        id: 'editedOn',
        label: t('CampaignHistory.list.columns.editedOn'),
      },
      {
        id: 'stringified_editor',
        label: t('CampaignHistory.list.columns.editor'),
      },
    ];
  }, [t]);

  const tableMaxHeight = 'calc(90dvh - (64px + 116px))';

  // They are required
  const rowsPerPageOptions = useMemo(() => [], []);
  const onPageChange = useCallback(() => {}, []);
  const onRowsPerPageChange = useCallback(() => {}, []);

  return (
    <Box sx={styles.container}>
      <PageHead breadcrumbs={breadcrumbs} title={t('CampaignHistory.title')} />

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Select
            onChange={setFilterAction}
            value={filterAction}
            label={t('CampaignHistory.filters.action.label')}
            options={filterOptions}
          />
        </Grid>
        <Grid item xs={4}>
          <InputLabel shrink>
            <Text variant="small" component="span">
              {t('CampaignHistory.filters.fulltext.label')}
            </Text>
          </InputLabel>
          <InputBase
            autoFocus
            value={fulltextValue}
            onChange={handleFulltextChange}
            placeholder={t('CampaignHistory.filters.fulltext.placeholder')}
            endAdornment={<IconSVG icon="search" size={24} />}
          />
        </Grid>
      </Grid>

      <Table
        tableMaxHeight={tableMaxHeight}
        rows={normalizedCompanyHistory}
        disablePagination={true}
        rowsPerPageOptions={rowsPerPageOptions}
        columns={columns}
        page={1}
        rowsPerPage={1}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Box>
  );
};

export default CampaignHistory;
