import {useMemo} from 'react';
import {SxProps, Theme} from '@mui/material';

const warningBox: SxProps<Theme> = {
  width: '100%',
  display: 'flex',
  alignItems: 'top',
  backgroundColor: theme => theme.palette.warning.light,
  border: theme => `1px solid ${theme.palette.warning.main}`,
  borderRadius: '10.5px',
  marginLeft: theme => theme.spacing(-1.5),
  py: theme => theme.spacing(0.1),
  px: theme => theme.spacing(0.4),
  gap: theme => theme.spacing(0.4),
};

export const useStyles = () => {
  const helperTextContainer = useMemo<SxProps<Theme>>(
    () => ({
      display: 'flex',
      alignItems: 'top',
      gap: theme => theme.spacing(0.8),
      marginTop: theme => theme.spacing(0.4),
      py: theme => theme.spacing(0.4),
      px: theme => theme.spacing(1),
    }),
    [],
  );

  const tooltip = useMemo<SxProps<Theme>>(() => ({height: '20px'}), []);

  return {helperTextContainer, warningBox, tooltip};
};
